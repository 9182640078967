/* src/Wave.css */
.wave-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    background-color: rgba(0, 0, 0, 0.03);
}

.wave {
    width: 5px;
    height: 15px;
    margin: 0 3px;
    border-radius: 50px;
    animation: wave 1.2s infinite ease-in-out;
}

.wave1 {
    background: linear-gradient(180deg, #4b0908, #6a0c0b);
}

.wave2 {
    background: linear-gradient(180deg, #6a0c0b, #aa0505);
}

.wave3 {
    background: linear-gradient(180deg, #aa0505, #fbca03);
}

.wave4 {
    background: linear-gradient(180deg, #fbca03, #b97d10);
}

.wave5 {
    background: linear-gradient(180deg, #b97d10, #4b0908);
}

.wave6 {
    background: linear-gradient(180deg, #4b0908, #6a0c0b);
}

.wave7 {
    background: linear-gradient(180deg, #6a0c0b, #aa0505);
}

.wave8 {
    background: linear-gradient(180deg, #aa0505, #fbca03);
}

.wave9 {
    background: linear-gradient(180deg, #fbca03, #b97d10);
}

.wave10 {
    background: linear-gradient(180deg, #b97d10, #4b0908);
}

.wave11 {
    background: linear-gradient(180deg, #4b0908, #6a0c0b);
}

@keyframes wave {
    0%, 40%, 100% {
        transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1);
    }
}

.wave:nth-child(1) {
    animation-delay: -1.2s;
}
.wave:nth-child(2) {
    animation-delay: -1.1s;
}
.wave:nth-child(3) {
    animation-delay: -1s;
}
.wave:nth-child(4) {
    animation-delay: -0.9s;
}
.wave:nth-child(5) {
    animation-delay: -0.8s;
}
.wave:nth-child(6) {
    animation-delay: -0.7s;
}
.wave:nth-child(7) {
    animation-delay: -0.6s;
}
.wave:nth-child(8) {
    animation-delay: -0.5s;
}
.wave:nth-child(9) {
    animation-delay: -0.4s;
}
.wave:nth-child(10) {
    animation-delay: -0.3s;
}
.wave:nth-child(11) {
    animation-delay: -0.2s;
}
