* {
  margin: 0;
  padding: 0;
  outline: none;
}

body {
  font-family: 'Roboto', sans-serif;
  background: #F2F2F2;
  /* margin-right: 55px; */
}

/* common styles */

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-spacebetween {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.ml-1{
  margin-left: 10px;
}

.mt-1{
  margin-top: 10px;
}

.text-center{
  text-align: center;
}

.mx-1 {
  margin-left: 5px;
  margin-right: 5px;
}

.ml-1 {
  margin-left: 10px;
}

.mt-2{
  margin-top: 20px !important;
}

/* ----------- */


#webpack-dev-server-client-overlay {
  display: none;
}

.app+div>div {
  height: 100%;
  width: 100% !important
}

.wrapper {
  height: 85%;
  margin-right: 55px;
}

.left-content {
  margin-right: auto;
  /* Pushes the right content to the end */
}

.right-content {
  margin-left: auto;
  /* Pushes the left content to the beginning */
}

.friday {
  color: #004C49;
}

.container {
  position: absolute;
  width: 100%;
  height: 91.5%;
  left: 0px;
  margin: 0px auto;
  display: inline-block;
}

div.app {
  position: relative;
  width: 30%;
  height: 100%;
  background-color: #004C49;
  /* background-image: url('../public/images/chat-bg-high.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */

}

.spinner {
  width: 70%;
  height: 100% !important;
}

.reportplaceholder{
  width: 70%;
  height: 100% !important;
}

.innerreportplaceholder{
  width: 100%;
  height: 100% !important;
}

.iframepbi{
  width:100%;
  height:100%;
}

div.app:before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  /* box-shadow: inset 0 80px 85px -35px #3c33b0; */
  z-index: 0;
}

div.app:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  bottom: 0;
  /* box-shadow: inset 0 -80px 85px -35px #3c33b0; */
  z-index: 0;
}

div.head {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 48px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.7);
  padding-top: 10px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

div.head span.title {
  position: absolute;
  width: 100%;
  display: inline-block;
  left: 0;
  font-size: 16px;
  font-weight: 500;
  margin-top: 2px;
  color: #fff;
}

div.head span.messages-notification {
  position: relative;
  float: left;
  font-size: 18px;
  font-weight: normal;
  margin-left: 15px;
  color: #fff;
  margin-top: 2px;
}

div.head span.messages-notification span.count {
  position: absolute;
  font-size: 10px;
  width: 17px;
  height: 17px;
  display: inline-block;
  background: #f42052;
  line-height: 17px;
  border-radius: 100%;
  margin-top: 6px;
  margin-left: -10px;
}

div.head span.create-new {
  float: right;
  font-size: 17px;
  font-weight: normal;
  margin-right: 15px;
  color: #fff;
  margin-top: 2px;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

div.search-bar {
  position: relative;
  margin-top: 15px;
  text-align: center;
  z-index: 1;
}

div.search-bar .ip-search {
  width: 85%;
  border: none;
  font-size: 16px;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.25);
  border-radius: 50px;
  border: 1px solid rgba(60, 51, 176, 0.7);
  box-shadow: inset 0 25px 25px -15px rgba(60, 51, 176, 0.15);
}

div.search-bar .ip-search::placeholder {
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 1px;
  font-size: 14px;
}

div.search-bar i.fa {
  position: absolute;
  right: 0;
  margin-right: 25px;
  margin-top: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

div.chat-messages {
  position: relative;
  margin-top: 15px;
  z-index: 10;
  height: 96%;
}

div.chat-messages div.chat {
  position: relative;
  width: 70%;
  height: 100%;
  background: #fcfcfe;
  left: 16%;
  margin-top: 40px;
  padding: 20px;
  border-radius: 5px;
  font-size: 15px;

  box-shadow: 30px 0 0 -15px #3cab9a, 60px 0 0 -30px #007b85, -30px 0 0 -15px #3cab9a, -60px 0 0 -30px #007b85, 0 0 25px rgba(255, 255, 255, 0.5);
  /* 30px 0 0 -15px #42d35b, 60px 0 0 -30px #00B050, -30px 0 0 -15px #42d35b, -60px 0 0 -30px #00B050, 0 0 25px rgba(255, 255, 255, 0.5); */
}

div.chat div.chat-content>span {
  margin-bottom: 12px;
}

div.chat div.chat-content span.friend {
  position: relative;
  /* max-width: 250px; */
  max-width: max-content;
  display: inline-block;
  background: #004C49;
  padding: 10px;
  box-shadow: 2px 2px 20px -2px #004C4947;
  color: #fff;
  border-radius: 15px 15px 15px 2px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* scroll bar */

/* Custom scrollbar styles */
.chat-content {
  max-height: 95%;
  /* Adjust this height as needed */
  overflow-y: auto;
  padding-right: 16px;
  /* Add space between the content and the scrollbar */
  scrollbar-width: thin;
  /* For Firefox */
  scrollbar-color: #004C49 #f0f0f0;
  /* For Firefox */
}

/* For Webkit browsers */
.chat-content::-webkit-scrollbar {
  width: 12px;
  /* Adjust the width as needed */
}

.chat-content::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Track color */
  border-radius: 10px;
  /* Roundness of the scroll track */
}

.chat-content::-webkit-scrollbar-thumb {
  background-color: #004C49;
  /* Thumb color */
  border-radius: 10px;
  /* Roundness of the scroll thumb */
  border: 2px solid #f0f0f0;
  /* Padding around the thumb for spacing */
}

.chat-content::-webkit-scrollbar-button {
  display: none;
  /* Remove the up and down arrows */
}

/* Optional: to remove scrollbar corner if visible */
.chat-content::-webkit-scrollbar-corner {
  background: #f0f0f0;
}


/* scrollbar ends */

div.chat div.chat-content span.friend.first {
  border-radius: 15px 15px 15px 2px;
}

div.chat div.chat-content span.friend.last {
  border-radius: 2px 15px 15px 15px;
}

div.chat div.chat-content span.friend span.time {
  position: absolute;
  display: block;
  right: 0;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 10px;
  font-weight: 500;
  color: rgba(60, 51, 176, 0.5);
}

div.chat div.chat-content span.you {
  position: relative;
  float: right;
  /* max-width: 250px; */
  max-width: max-content;
  background: #fff;
  display: inline-block;
  padding: 10px;
  color: #004C49;
  border-radius: 15px 15px 2px 15px;
  box-shadow: 2px 2px 20px #004C4940, inset -10px -10px 55px #ffffff;
  border: 1px solid #004C49;
  margin-top: 5px;
  margin-bottom: 5px;
}

div.chat div.chat-content span.you span.time {
  position: absolute;
  display: block;
  right: 0;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

div.chat div.chat-content span.you.first {
  border-radius: 15px 15px 2px 15px;
}

div.chat div.chat-content span.you.last {
  border-radius: 15px 2px 15px 15px;
}

div.msg-box {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(60, 51, 176, 0.1);
  overflow: hidden;
}

div.msg-box .ip-msg {
  width: 85%;
  font-size: 14px;
  padding: 15px;
  padding-right: 30%;
  color: rgba(60, 51, 176, 0.9);
  border: none;
  background: rgba(0, 0, 0, 0.03);
}

div.msg-box .ip-msg::placeholder {
  color: rgba(60, 51, 176, 0.4);
}

div.msg-box span.btn-group {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}


.logo {
  height: 28px;
  width: 223px;
}

.send {
  height: 24px;
  width: 24px;
}


/* spinner style starts */

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* spinner style ends */

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #004C49 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}

.recording-indicator {
  color: red;
  font-size: 14px;
  width: 85%;
  padding: 15px;
  padding-right: 30%;
  border: none;
  background: rgba(0, 0, 0, 0.03);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

/* modal */
.darkBG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
}

.modal {
  width: 330px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modalHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.close-icon {
  margin: 0;
  cursor: pointer;
  color: #007b85;
}

.modalContent {
  margin-top: 15px;
}

/* hover-button */


/* From Uiverse.io by adamgiebl */ 
.hover-button {
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 0.7em 2em;
  border: 3px solid #007b85;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  color: #007b85;
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
  cursor: pointer;
}

.hover-button:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: '';
  background-color: #007b85;
  z-index: -1;
}

.hover-button:hover, .hover-button:focus {
  color: white;
}

.hover-button:hover:before, .hover-button:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.hover-button:active {
  transform: scale(0.9);
}

.grey-hover-button {
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 0.7em 2em;
  border: 3px solid #737373;
  border-radius: 2px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
  color: #737373;
  text-decoration: none;
  transition: 0.3s ease all;
  z-index: 1;
  cursor: pointer;
}

